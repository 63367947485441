import SConversationItem from "@skeletons/hub/elements/SConversationItem";

export default function SChatList() {
  return (
    <>
      <SConversationItem />
      <SConversationItem />
      <SConversationItem />
      <SConversationItem />
      <SConversationItem />
      <SConversationItem />
    </>
  );
}
